import React, {useState} from 'react';
import {Button, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import axios from "../../../axiosInstance";
import {utils, writeFile} from 'xlsx';
import {useRecoilValue} from "recoil";
import {pageSetState} from "../../../store/pageSetStore";
import dayjs from "dayjs";
import {colorState} from "../../../store/colorStore";

const DownloadMember = () => {


    const [downloadMemRole, setDownloadMemRole] = useState('전체')
    const color = useRecoilValue(colorState);
    const pageSet = useRecoilValue(pageSetState);

    const downloadTargetHandler = (e) => {
        setDownloadMemRole(e.target.value);
    }

    const download = () => {
        axios.get(`/api/member/download/${downloadMemRole}`).then((response) => {
            console.log(response.data);
            downloadAsExcel(response.data);
        });
    }

    const downloadAsExcel = (data) => {
        // 엑셀 파일의 제목 행을 생성합니다.
        const headers = [
            "등급",
            '상태',
            "아이디",
            "회원명",
            "대표자명",


            "구분",
            "사업자번호",
            "주민등록번호",


            "연락처",
            "주소",
            "상세주소",
            '업종',
            '업태',
            '품목',
            "은행명",
            "계좌번호",
            "예금주",
            '비고'
        ];

        // 데이터 행을 생성합니다.

        const rows = data.map((item) => {
            const dType = item.memberDetail?.dType || ''; // 구분 값
            const shopNum = dType === '사업자' ? item.memberDetail?.shopNum || '' : ''; // 사업자 번호
            const birthday = dType === '사업자' ? '' : item.memberDetail?.birthday || ''; // 생년월일
            return [
                item.memRole || '',
                item.isFired === 'Y' ? '해지' : '정상',
                item.memId || '',
                item.memName || '',
                item.memberDetail?.shopOwner || '',
                // 구분
                dType,
                // 사업자
                shopNum,
                // 생년월일
                birthday,
                item.memberDetail?.shopTell || '',
                item.memberDetail?.shopAddr2 || '',
                item.memberDetail?.shopAddrDetail || '',
                item.memberDetail?.shopType || '',
                item.memberDetail?.shopItem || '',
                item.memberDetail?.shopMenu || '',
                item.memberDetail?.bankName || '',
                item.memberDetail?.bankNum || '',
                item.memberDetail?.bankOwner || '',
                item.memberDetail?.shopMemo || ''
            ];
        });

        // 제목 행과 데이터 행을 합쳐 완성된 엑셀 파일을 생성합니다.
        const ws = utils.json_to_sheet([headers].concat(rows), {skipHeader: true});
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");

        // 파일 이름을 지정합니다.
        const today = dayjs().format('YYYY-MM-DD');
        const filename = `${pageSet} ${downloadMemRole} 회원목록 ${today}.xlsx`;

        // 엑셀 파일을 다운로드합니다.
        writeFile(wb, filename);
    };


    return (
        <>
            <Grid item xs={1} container alignItems="center" justifyContent="center">
                <FormControl fullWidth variant="standard">
                    <InputLabel>회원정보 다운로드</InputLabel>
                    <Select onChange={downloadTargetHandler} name="memRole" defaultValue={'전체'}>
                        <MenuItem value="전체">전체</MenuItem>
                        <MenuItem value="대행사">대행사</MenuItem>
                        <MenuItem value="지사">지사</MenuItem>
                        <MenuItem value="총판">총판</MenuItem>
                        <MenuItem value="대리점">대리점</MenuItem>
                        <MenuItem value="가맹점">가맹점</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={1} container alignItems="center" justifyContent="center">
                <Button onClick={download} size="small" variant="contained" fullWidth
                        style={{backgroundColor: color, marginTop: '15px'}}>
                    다운로드
                </Button>
            </Grid>
            <Grid item xs={4} container alignItems="center" justifyContent="center">
            </Grid>
        </>
    );
};

export default DownloadMember;
