import {
    Box,
    IconButton,
    InputAdornment,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextareaAutosize,
    TextField
} from "@mui/material";
import React, {useEffect, useState} from "react";
import SaveIcon from '@mui/icons-material/Save';
import axios from '../../../axiosInstance';
import {useRecoilValue} from "recoil";
import {memberState} from "../../../store/memberStore";

export const BasicInfoModal = ({data}) => {

    const [memo, setMemo] = useState('');
    const [limitAmt, setLimitAmt] = useState(0)
    const [isCheck, setIsCheck] = useState(false);
    const memberInfo = useRecoilValue(memberState);
    const memCode = data.memCode;

    useEffect(() => {
        getMemo();
        fetchPayCheckData();
    }, []);

    useEffect(() => {


    }, [isCheck]);

    const payCheckSave = () => {
        if (isCheck) {
            patchPayCheckData();
        } else if (!isCheck) {
            deletePayCheckData();
        }
    }


    const patchPayCheckData = () => {
        axios.post('/api/admin/payCheck', null, {
            params: {
                memCode,
                limitAmt
            }
        })
            .then(response => {
                alert("매출 체크 정보가 등록되었습니다.");
            })
            .catch(error => {
                alert("매출 체크 정보 등록에 실패했습니다.");
            });
    }

    const deletePayCheckData = () => {
        axios.delete('/api/admin/payCheck', {
            params: { memCode }
        })
            .then(response => {
                alert("매출 체크 정보가 삭제되었습니다.");
            })
            .catch(error => {
                alert("매출 체크 정보 삭제에 실패했습니다.");
            });
    }

    const getMemo = () => {
        axios.get(`/api/admin/memo?memCode=${data.memCode}`).then(response => {
            setMemo(response.data);
        }).catch(err => {
            setMemo('');
        })
    }
    const fetchPayCheckData = () => {
        axios.get('/api/admin/myPayCheck', {
            params: { memCode }
        }).then(response => {
            if (response.data && Object.keys(response.data).length > 0) {
                setLimitAmt(response.data.LIMIT_AMT);
                setIsCheck(true);
            } else {
                setIsCheck(false);
            }
        }).catch(err => {
            // setPayCheckData([]);
        })
    }

    const handleSaveClick = () => {
        axios.patch(`/api/admin/memo?memCode=${data.memCode}&memo=${memo}`)
            .then(response => {
                alert('저장되었습니다.')
            })
            .catch(err => {
                alert('저장에 실패했습니다.');
            });
    }

    return (
        <>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            등급
                        </TableCell>
                        <TableCell colSpan={2} sx={{width: '35%'}} align="center">{data.memRole}</TableCell>
                        <TableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            구분
                        </TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.dType || ''}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>회원ID</TableCell>
                        <TableCell colSpan={2} align="center">{data.memId}</TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>회원명</TableCell>
                        <TableCell colSpan={2} align="center">{data.memName}</TableCell>

                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>대표자명</TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.shopOwner}</TableCell>
                        {data.memberDetail.dType === '사업자' ?
                            <>
                                <TableCell align="center"
                                           sx={{
                                               bgcolor: 'grey.200',
                                               width: '15%',
                                               fontSize: '12px'
                                           }}>사업자번호</TableCell>
                                <TableCell colSpan={2} align="center">{data.memberDetail.shopNum}</TableCell>

                            </>
                            :
                            <>
                                <TableCell
                                    align="center"
                                    sx={{bgcolor: 'grey.200', width: '15%', fontSize: '12px'}}>생년월일</TableCell>
                                <TableCell colSpan={2} align="center">{data.memberDetail.birthday}</TableCell>
                            </>

                        }
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>업종</TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.shopType}</TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>업태</TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.shopItem}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>품목</TableCell>
                        <TableCell colSpan={1} align="center">{data.memberDetail.shopMenu}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            연락처
                        </TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.shopTell}</TableCell>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            이메일
                        </TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.memEmail}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            연락처2
                        </TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.shopTell2}</TableCell>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            연락처3
                        </TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.shopTell3}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            주소
                        </TableCell>
                        <TableCell colSpan={5}
                                   align="center">{data.memberDetail.shopAddr1 + ' ' + data.memberDetail.shopAddr2 + ' ' + data.memberDetail.shopAddrDetail}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            은행명
                        </TableCell>
                        <TableCell align="center" colSpan={2}>{data.memberDetail.bankName}</TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>예금주</TableCell>
                        <TableCell align="center" colSpan={2}>{data.memberDetail.bankOwner}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} align="center" scope="row" sx={{bgcolor: 'grey.200', width: '15%'}}>
                            계좌번호
                        </TableCell>
                        <TableCell colSpan={5} align="center">{data.memberDetail.bankNum}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={1} align="center" scope="row"
                                   sx={{bgcolor: 'grey.200', width: '15%'}}>
                            비고
                        </TableCell>
                        <TableCell colSpan={5} align="center">{data.memberDetail.shopMemo}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>가입일시</TableCell>
                        <TableCell colSpan={2} align="center">{data.regDate}</TableCell>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>승인일시</TableCell>
                        <TableCell colSpan={2} align="center">{data.memberDetail.permitDate}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>등록ID</TableCell>
                        <TableCell colSpan={5} align="center">{data.regMemId}</TableCell>
                    </TableRow>
                    {memberInfo.role === 'ROLE_ADMIN' &&
                        <>
                            <TableRow>
                                <TableCell colSpan={1} align="center" sx={{ bgcolor: 'grey.200', width: '15%' }}>
                                    메모
                                </TableCell>
                                <TableCell colSpan={5} align="center">
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <TextareaAutosize
                                            minRows={3}
                                            style={{
                                                width: '90%',
                                                padding: '8px',
                                                borderRadius: '4px',
                                                borderColor: 'rgba(0, 0, 0, 0.23)',
                                                resize: 'none' // 크기 변경 불가능하도록 설정
                                            }}
                                            name="memo"
                                            value={memo}
                                            onChange={(e) => setMemo(e.target.value)}
                                        />
                                        <IconButton onClick={handleSaveClick} sx={{ marginLeft: '8px' }}>
                                            <SaveIcon />
                                        </IconButton>
                                    </Box>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={1} align="center" sx={{bgcolor: 'grey.200', width: '15%'}}>
                                    매출 체크
                                </TableCell>
                                <TableCell colSpan={3} align="center">
                                    <TextField
                                        size={'small'}
                                        sx={{width: '90%'}}
                                        name="limitAmt"
                                        value={limitAmt}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">원</InputAdornment>,
                                            inputProps: {
                                                style: {textAlign: 'right'}
                                            }
                                        }}
                                        onChange={(e) => setLimitAmt(e.target.value)}
                                    />
                                </TableCell>
                                <TableCell colSpan={1} align="center">
                                    <Switch
                                        checked={isCheck}
                                        onChange={(e) => setIsCheck(e.target.checked)}
                                        size={'small'}/>
                                </TableCell>
                                <TableCell colSpan={1} align="center">
                                    <IconButton onClick={payCheckSave}><SaveIcon/></IconButton>
                                </TableCell>
                            </TableRow>
                        </>

                    }
                </TableBody>
            </Table>
        </>
    )
}
